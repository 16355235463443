
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { TransportTypeConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class EditModal extends Vue {

    @Prop({
        default: () => new OM.TransportDistanceCalculationVM()
    }) model: OM.TransportDistanceCalculationVM;
    localModel: OM.TransportDistanceCalculationVM = new OM.TransportDistanceCalculationVM();

    international: boolean = false;

    @Prop() callback: any;

    transportTypes: string[] = [];

    created() {
        this.localModel = this.model;
        TransportTypeConfigurationClient.getAllTransportTypes()
        .then(x => {
            this.transportTypes = x.map(y => y.transportType);
        })
    }

    get canSave(){
        return this.localModel.from && this.localModel.to && this.localModel.distanceKM && this.localModel.transportType;
    }

    save() {
        if(!this.canSave)
            return;

        this.callback(this.localModel);
    }
    
}
